jQuery( function() {

  jQuery(".select_all").click(
    function(){
      $("input[type='checkbox'].allable").each(function(i,box){
        $(box).attr("checked",true);
      });
      return false;
    }
  );

  jQuery(".select_none").click(
    function(){
      $("input[type='checkbox'].allable").each(function(i,box){
        $(box).attr("checked",false);
      });
      return false;
    }
  );

});


